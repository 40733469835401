import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private _router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    let redirectUrl = state.url;

    if ( redirectUrl === '/sign-out' )
    {
        redirectUrl = '/';
    }
    if (!this.authService.isAuthenticated()) {
      // Redirect to the sign-in page
      this._router.navigate(['sign-in'], {queryParams: {redirectUrl}});
      return false;
      //window.location.href = '/sign-in';
    }
    return this.authService.isAuthenticated();
  }
}
