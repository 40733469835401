import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';


import { AppSessionService } from '@app/core/auth/app-session.service';
import { AppUrlService } from '../app/core/auth/app-url.service';
import { AppAuthService } from './abp/auth/app-auth.service';
import { AppRouteGuard } from './abp/auth/auth-route-guard';
import { LocalizePipe } from '@app/shared/pipes/localize.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

import { BlockDirective } from './abp/directives/block.directive';
import { BusyDirective } from './abp/directives/busy.directive';
import { EqualValidator } from './abp/directives/equal-validator.directive';
//import { AbpSharedModule } from './abp/abp-shared.module';
@NgModule({
    imports: [
        CommonModule,
        //AbpSharedModule,
        RouterModule,
        
    ],
    declarations: [
        LocalizePipe,
        BlockDirective,
        BusyDirective,
        EqualValidator
    ],
    exports: [
        LocalizePipe,
        BlockDirective,
        BusyDirective,
        EqualValidator,
        CdkTableModule,
        CdkTreeModule,
        DragDropModule,
        ScrollingModule
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AppAuthService,
                AppRouteGuard
            ]
        };
    }
}
