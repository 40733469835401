import {NgModule, ModuleWithProviders} from '@angular/core';
import {AppSessionService} from '@app/core/auth/app-session.service';
import { AuthGuard } from './auth-guard.service';
import { AuthService } from './auth.service';
import { AppUrlService } from './app-url.service';



@NgModule({
    providers: []
})
export class AuthCoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: AuthCoreModule,
            providers: [
                AppSessionService,
                AppUrlService,
                AuthService,
                AuthGuard


            ]
        };
    }
}