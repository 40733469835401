import {NgModule, APP_INITIALIZER} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app.routing';
import {AppSharedModule} from './shared/shared.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {
    PerfectScrollbarModule,
    PERFECT_SCROLLBAR_CONFIG,
    PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import * as _ from 'lodash';
import {AppComponent} from './app.component';


import {PlatformLocation} from '@angular/common';
import {AppConsts} from '@app/shared/app-consts';
import {AbpHttpInterceptor} from 'abp-ng2-module';
import {APP_API_BASE_URL} from '@core/app-services/app-services';
import {AppServicesModule} from '@core/app-services/app-services.module';

import {PROFILE_API_BASE_URL} from '@core/app-services/profile-services';
import {CONTENT_API_BASE_URL} from '@core/app-services/content-services';
import {COURSE_API_BASE_URL} from '@core/app-services/course-services';
import {MDynamicDialogModule} from 'm-dynamic-dialog';
import {AppInitializer} from '@app/app-initializer';
import { AuthCoreModule } from './core/auth/auth.core.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DialogModule } from '@ngneat/dialog';
import { ShellModule } from './shell/shell.module';
import { UtilsModule } from './shared/utils/utils.module';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false

};

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getCurrentLanguage(): string {
    return 'en-US';
    if (abp.localization.currentLanguage.name) {
        return abp.localization.currentLanguage.name;
    }

    // todo: Waiting for https://github.com/angular/angular/issues/31465 to be fixed.
    return 'en';


}

export function getBaseHref(platformLocation: PlatformLocation): string {
    const baseUrl = platformLocation.getBaseHrefFromDOM();
    if (baseUrl) {
        return baseUrl;
    }

    return '/';
}




@NgModule({
    declarations: [
        AppComponent,

    ],
    imports: [
        BrowserAnimationsModule,
        AuthCoreModule.forRoot(),
        AppRoutingModule,
        AppSharedModule,
        HttpClientModule,
        NgbModule,
        NgxSpinnerModule,
        ShellModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        PerfectScrollbarModule,
        UtilsModule,
        AppServicesModule,
        MDynamicDialogModule.forRoot(),
        DialogModule.forRoot()

    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
        {provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true},
        { provide: APP_API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
        { provide: PROFILE_API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
        { provide: CONTENT_API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
        { provide: COURSE_API_BASE_URL, useFactory: () => AppConsts.remoteServiceBaseUrl },
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializer: AppInitializer) => appInitializer.init(),
            deps: [AppInitializer],
            multi: true,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
