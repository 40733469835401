import { Component, OnInit } from '@angular/core';
import {MenuItem} from "@shared/common/menu-item";

@Component({
  selector: 'shell-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  menuItems: MenuItem[] = [
    {
      icon: 'assets/images/sidebar/home.png',
      name: 'Home',
      items: [],
      permissionName: '',
      route: '/guide/dashboard'
    },
    {
      icon: 'assets/images/sidebar/profile.png',
      name: 'Avatar',
      items: [],
      permissionName: '',
      route: '/profile'
    },
    {
      icon: 'assets/images/sidebar/timeline.png',
      name: 'Schedule',
      items: [],
      permissionName: '',
      route: '/guide/schedule'
    },
    // {
    //   icon: 'assets/images/sidebar/timeline.png',
    //   name: 'Schedule',
    //   items: [],
    //   permissionName: '',
    //   route: '/guide/achievements'
    // },
    // {
    //   icon: 'assets/images/sidebar/timeline.png',
    //   name: 'Syllabus',
    //   items: [],
    //   permissionName: '',
    //   route: '/syllabus'
    // },
    {
        icon: 'assets/images/sidebar/club.png',
        name: 'Nineties Club',
        items: [],
        permissionName: '',
        route: '/guide/nineties-club'
    }
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
