<ng-container *ngIf='!hideNavBar'>
<shell-navbar (toggleHideSidebar)="toggleHideSidebar($event)"></shell-navbar>
</ng-container>
<div class="layout-container" [class.hide-sidebar]="hideSidebar">
    <div class="sidebar">
        <shell-side-bar></shell-side-bar>
    </div>

    <div class="content">
        <router-outlet></router-outlet>
    </div>
</div>
