<!-- Navbar (Header) Starts -->
<nav class="header-navbar navbar navbar-expand-lg navbar-light bg-faded">
    <div class="container-fluid">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle d-lg-none float-left" data-toggle="collapse" (click)="toggleSidebar()">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <span class="d-lg-none navbar-right navbar-collapse-toggle">
                <a class="open-navbar-container" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="navbarSupportedContent">
                    <i class="ft-more-vertical"></i>
                </a>
            </span>
            <a [routerLink]="['/']" class="logo-text">
                <div class="logo-img text-center">
                    <img [src]="logoUrl" />
                </div>
                <span class="text align-middle"></span>
            </a>
            <div class="page-title">{{shell.title$ | async}}</div>


        </div>
        <div class="navbar-container">
            <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="isCollapsed">
                <ul class="navbar-nav d-flex flex-col flex-md-row">
                    <li>
                        <a class="nav-link position-relative">
                            
                            
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link position-relative d-flex d-flex-row">
                            <div>
                                <div><span class="user-name">{{(profile.user$ | async)?.fullName}}</span></div>
                                <div class="version"><span >Version {{appConfig.version}}</span></div>
                            </div>
                            
                            <img src="{{profile.avatarImage$ | async}}" style="height: 40px; width: 40px; vertical-align: middle; border-radius: 50%;" alt=""/>
                        </a>
                        
                    </li>
                    <li class="nav-item d-block">
                        <a class="nav-link position-relative notification-sidebar-toggle d-flex flex-row" [ngbTooltip]="'Logout'" (click)="logout()" style="margin-top: 6px">
                            <i class="ft-log-out font-medium-3 blue-grey darken-4"></i>
                            <p class="d-lg-none">Logout</p>
                        </a>
                    </li>
                </ul>

            </div>
        </div>
    </div>
</nav>
<!-- Navbar (Header) Ends -->
