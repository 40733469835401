// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  hmr       : false,
  appConfig: 'appconfig.json',
  agora: {
    appId: '0b6f5618cd784b42ac7779c26fbfcb8b'
  }
};
