import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class ShellService {

  onNavBarStateChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  onSidebarStateChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  private readonly _fullUserName: BehaviorSubject<string> = new BehaviorSubject<string>('');
  fullUserName$ = this._fullUserName.asObservable();


  private readonly _title: BehaviorSubject<string> = new BehaviorSubject<string>('');
  title$ = this._title.asObservable();

  set title(value: string) {
      this._title.next(value);
  }

  constructor(private spinner: NgxSpinnerService) { }

  showNavBar(): void {
    this.onNavBarStateChanged.next(true);
  }

  hideNavBar(): void {
    this.onNavBarStateChanged.next(false);
  }

  toggleNavBar(): void {
    this.onNavBarStateChanged.next(!this.onNavBarStateChanged.value);
  }

  showSidebar(): void {
    this.onSidebarStateChanged.next(true);
  }

  hideSidebar(): void {
    this.onSidebarStateChanged.next(false);
  }

  toggleSidebar(): void {
    this.onSidebarStateChanged.next(!this.onSidebarStateChanged.value);
  }


  showBusy(){
    this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'large',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
  }

  hideBusy(){
    this.spinner.hide();
  }

}
