import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { AppSessionService } from '../auth/app-session.service';
import { UserDto, UserAppService } from '../app-services/app-services';
import { ScoreModel } from './score.model';
import { AccessModeKind, ProfileAppService,  StudentEnrollmentDto,  SubscriptionStatusKind } from '../app-services/profile-services';

@Injectable({
    providedIn: 'root'
})
export class StudentProfileService implements Resolve<any> {


    private _userId = 0;
    private _user: BehaviorSubject<UserDto|null> = new BehaviorSubject<UserDto>(null);
    defaultAvatarUrl: string = 'assets/images/profile/avatar.png'
    get studentId() : number {
        return this._userId;
    }

    get user$(): Observable<UserDto> {
        return this._user.asObservable()
    }


    get user(): UserDto {
        return this._user.getValue();
    }


    get subscriptionActive(): boolean {
        return this.currentEnrollment.subscriptionStatus == SubscriptionStatusKind.Active;
    }

    get isTrial(): boolean {
        
        if(!this.currentEnrollment) return true;

        return  this.currentEnrollment.isTrial;
    }

    get isPreview(): boolean {
        if(!this.currentEnrollment) return true;
        return this.currentEnrollment.accessMode == AccessModeKind.Preview;
      }
    
      get isCourseActive(): boolean {
        return this.currentEnrollment && this.currentEnrollment.isCourseActive;
      }


    private _avatarImage: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public readonly avatarImage$ = this._avatarImage.asObservable();

    private _gameScore: BehaviorSubject<ScoreModel> = new BehaviorSubject<ScoreModel>(null);
    public readonly gameScore$ = this._gameScore.asObservable();



    private _currentEnrollment: BehaviorSubject<StudentEnrollmentDto> = new BehaviorSubject<StudentEnrollmentDto>(null);
    

    get currentEnrollment$(): Observable<StudentEnrollmentDto> {
        return this._currentEnrollment.asObservable();
    }

    get currentEnrollment(): StudentEnrollmentDto {
        return this._currentEnrollment.getValue();
    }


    constructor(private _userService: UserAppService, private _profileApi: ProfileAppService, private _sessionService: AppSessionService) {

    }



    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Promise<void>  {
        if(this._sessionService.user && this._sessionService.user.id != this._userId){
            await this.loadUser(this._sessionService.user.id);
        }
    }

    

    async loadUser(userId: number): Promise<void>{
        this._userId = userId;
        var result= await this._userService.get(userId).toPromise();
        var profileResult = await this.loadProfile(result);
        this._user.next(result);

    }

    async loadProfile(user: UserDto): Promise<void> {
        this._userId = user.id;
        this._user.next(user);
        var result = await this._profileApi.getStudentProfile(this._userId).toPromise();
        var avatarUrl = this.defaultAvatarUrl;
        if(result.profile && result.profile.avatarImageUrl && result.profile.avatarImageUrl != "") {
            avatarUrl = result.profile.avatarImageUrl;
        }
        this._avatarImage.next(avatarUrl);

        const score = new ScoreModel();
        score.points = result.profile.pointsEarned;
        score.awards= result.profile.awardsEarned;

        

        this._currentEnrollment.next(result.profile.currentEnrollment);

        this._gameScore.next(score);


    }


    updateAvatar(imageUrl: string) {
        this._avatarImage.next(imageUrl);
    }

    
}