import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AppSessionService} from '@app/core/auth/app-session.service';
import {AuthenticateModel, AuthenticateResultModel, TokenAuthAppService, UserDto} from '@core/app-services/app-services';
import {LogService, TokenService, UtilsService} from 'abp-ng2-module';
import {AppConsts} from '@app/shared/app-consts';
import {UrlHelper} from '@core/helpers/url-helper';
import { Token } from '@angular/compiler';
import { LocalStorageService } from '@app/shared/utils/local-storage.service';

@Injectable()
export class AuthService {
    token: string;
  private authenticateModel: AuthenticateModel;

    constructor(private _sessionService: AppSessionService,
                private _tokenService: TokenService,
                private _utilsService: UtilsService,
                private _logService: LogService,
                private _router: Router,
                private _tokenAuthService: TokenAuthAppService,
                private _localStorageService: LocalStorageService) {
    }

    signupUser(email: string, password: string) {
        // your code for signing up the new user
    }

    async signinUser(email: string, password: string): Promise<AuthenticateResultModel> {
      const model = new AuthenticateModel({
        userNameOrEmailAddress: email,
        password: password,
        rememberClient: true
      });
      const result = await this._tokenAuthService
          .authenticate(model).toPromise();


      if(!result.isSuccessful) {
        return result;
      }    

      const user = new UserDto();
      user.id = result.user.id;
      user.fullName = result.user.fullName;
      user.emailAddress = result.user.emailAddress;
      user.userName = result.user.userName;
      localStorage.setItem('user', JSON.stringify(user));
      this.processAuthenticateResult(result);
      await this._sessionService.init();
      return result;

    }

    logout() {
        this.token = null;
      abp.auth.clearToken();
      localStorage.removeItem('user');
      this._router.navigate([AppConsts.signInRoute]);
    }

    getToken() {
        return this.token;
    }

    getUser() {
      return this._sessionService.user;
    }

    isAuthenticated() {
        // here you can check if user is authenticated or not through his token
        return this._sessionService.user !== null && this._sessionService.user !== undefined;
    }

  private processAuthenticateResult(result: AuthenticateResultModel): void {
    if (result.accessToken) {
      // Successfully logged in
      this.login(result.accessToken, 
        result.encryptedAccessToken, 
        result.expireInSeconds, result.refreshToken, result.refreshTokenExpireInSeconds, true);

    } else {
      // Unexpected result!

      this._logService.warn('Unexpected authenticateResult!');
      this._router.navigate([AppConsts.signInRoute]);
    }
  }


  private login( accessToken: string,
    encryptedAccessToken: string,
    expireInSeconds: number,
    refreshToken: string,
    refreshTokenExpireInSeconds: number,
    rememberMe?: boolean,
    redirectUrl?: string): void {

    const tokenExpireDate = rememberMe ? (new Date(new Date().getTime() + 1000 * expireInSeconds)) : undefined;

    this._tokenService.setToken(
        accessToken,
        tokenExpireDate
    );

    if (refreshToken && rememberMe) {
      let refreshTokenExpireDate = rememberMe
          ? new Date(
              new Date().getTime() + 1000 * refreshTokenExpireInSeconds
          )
          : undefined;
      this._tokenService.setRefreshToken(
          refreshToken,
          refreshTokenExpireDate
      );
  }

    this._utilsService.setCookieValue(
        AppConsts.authorization.encryptedAuthTokenName,
        encryptedAccessToken,
        tokenExpireDate,
        abp.appPath
    );

    let self = this;
        this._localStorageService.setItem(
            AppConsts.authorization.encryptedAuthTokenName,
            {
                token: encryptedAccessToken,
                expireDate: tokenExpireDate,
            }, ()=>{
                
            }
        );
  }

  private redirectToLoginResult(redirectUrl?: string): void {
    if (redirectUrl) {
        location.href = redirectUrl;
    } else {
        let initialUrl = UrlHelper.initialUrl;

        if (initialUrl.indexOf('/auth/sign-in') > 0) {
            initialUrl = AppConsts.appBaseUrl;
        }

        location.href = initialUrl;
    }
}
}
