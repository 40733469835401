import { Component, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LayoutService } from '../../../shared/services/layout.service';
import { ConfigService } from '../../../shared/services/config.service';
import { ShellService } from '@shell/shell.service';
import { StudentProfileService } from '@app/core/profile/student-profile.service';
import { AuthService } from '@app/core/auth/auth.service';
import { AppConfig } from '@app/app-config';
import { AppConsts } from '../../../shared/app-consts';

@Component({
  selector: "shell-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit {
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-left";
  public isCollapsed = true;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  public config: any = {};
  public logoUrl = AppConsts.logoUrl;
  constructor(public translate: TranslateService, private layoutService: LayoutService, public appConfig: AppConfig,
    private configService:ConfigService, private _shellServie: ShellService, 
    private _profileService: StudentProfileService, private _authService: AuthService) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");

  }

  async ngOnInit(): Promise<void> {
    this.config = this.configService.templateConf;
    await this.appConfig.checkVersion();
  }

  ngAfterViewInit() {
    if(this.config.layout.dir) {
      const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-left";
        }
    }
  }


  get shell(): ShellService {
      return this._shellServie;
  }

  get profile(): StudentProfileService {
      return this._profileService;
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  logout() {
    this._authService.logout()
  }
}
