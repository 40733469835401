import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from '@shell/layouts/full/full-layout.component';


import { AuthGuard } from './core/auth/auth-guard.service';
import { StudentProfileService } from './core/profile/student-profile.service';
import { ContentLayoutComponent } from './shell/layouts/content/content-layout.component';
import { EmptyLayoutComponent } from './shell/layouts/empty/empty-layout.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'guide',
    pathMatch: 'full',
  },
  {
    path: 'signed-in-redirect',
    redirectTo: 'guide',
    pathMatch: 'full',
  },
  {
    path: '',
    component: EmptyLayoutComponent,
    children: [
        { path: 'sign-in', loadChildren: () => import('app/modules/accounts/sign-in/sign-in.module').then(m => m.SignInModule) },
        { path: 'sign-out', loadChildren: () => import('app/modules/accounts/sign-out/sign-out.module').then(m => m.SignOutModule) }
    ]
  },

 
 
  
  {
    path: '', component: ContentLayoutComponent, data: { title: 'Content' }, canActivate: [AuthGuard],
    resolve: {
        profile: StudentProfileService,
    },
    children: [
     
      { path: 'quiz', loadChildren: () => import('app/modules/quiz/quiz.module').then(m => m.QuizModule) },
      { path: 'lessons', loadChildren: () => import('app/modules/lessons/lessons.module').then(m => m.LessonsModule) },
    ]
  },

  {
    path: '', component: FullLayoutComponent, data: { title: 'Guide' }, canActivate: [AuthGuard],
    resolve: {
        profile: StudentProfileService,
    },
    children: [
      { path: 'guide', loadChildren: () => import('./modules/guide/guide.module').then(m => m.StudentGuideModule) },
      { path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule) }
    ]
  },
  {
    path: '**',
    redirectTo: 'guide',
    pathMatch: 'full',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {

}