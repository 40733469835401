import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

//COMPONENTS
import { FooterComponent } from "./common/footer/footer.component";
import { NavbarComponent } from "./common/navbar/navbar.component";
import { NotificationSidebarComponent } from './common/notification-sidebar/notification-sidebar.component';
import { SideBarComponent } from './common/side-bar/side-bar.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { EmptyLayoutComponent } from './layouts/empty/empty-layout.component';
import { SharedModule } from 'shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';




@NgModule({
    exports: [
        FooterComponent,
        NavbarComponent,
        NotificationSidebarComponent,
        SideBarComponent,
        FullLayoutComponent,
        EmptyLayoutComponent,
        ContentLayoutComponent,

    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        PerfectScrollbarModule,
        SharedModule,
        NgxSpinnerModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        NotificationSidebarComponent,
        SideBarComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        EmptyLayoutComponent

    ]
})
export class ShellModule { }
