<!-- //////////////////////////////////////////////////////////////////////////// -->
<!-- START Notification Sidebar -->
<aside #sidebar id="notification-sidebar" class="notification-sidebar d-none d-sm-none d-md-block">
  <a class="notification-sidebar-close" (click)="onClose()">
    <i class="ft-x font-medium-3"></i>
  </a>
  <div class="side-nav notification-sidebar-content" [perfectScrollbar]>
    <div class="row">
      <div class="col-12 mt-1">
        <ngb-tabset>
          
          <ngb-tab>
            <ng-template ngbTabTitle><b>Chat</b></ng-template>
            <ng-template ngbTabContent>
              <div id="chatapp" class="col-12">
                <h6 class="mt-1 mb-3 text-bold-400 text-left">RECENT CHAT</h6>
                <div class="collection border-none">
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-12.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Elizabeth Elliott </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">5.00 AM</span>
                      </div>
                      <p class="text-muted font-small-3">Thank you </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-6.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Mary Adams </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">4.14 AM</span>
                      </div>
                      <p class="text-muted font-small-3">Hello Boo </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-11.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Caleb Richards </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">9.00 PM</span>
                      </div>
                      <p class="text-muted font-small-3">Keny ! </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-18.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">June Lane </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">4.14 AM</span>
                      </div>
                      <p class="text-muted font-small-3">Ohh God </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-1.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Edward Fletcher </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">5.15 PM</span>
                      </div>
                      <p class="text-muted font-small-3">Love you </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-2.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Crystal Bates </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">8.00 AM</span>
                      </div>
                      <p class="text-muted font-small-3">Can we </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-3.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Nathan Watts </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">9.53 PM</span>
                      </div>
                      <p class="text-muted font-small-3">Great! </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-15.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Willard Wood </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">4.20 AM</span>
                      </div>
                      <p class="text-muted font-small-3">Do it </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-19.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Ronnie Ellis </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">5.30 PM</span>
                      </div>
                      <p class="text-muted font-small-3">Got that </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-14.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Gwendolyn Wood </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">4.34 AM</span>
                      </div>
                      <p class="text-muted font-small-3">Like you </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-13.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Daniel Russell </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">12.00 AM</span>
                      </div>
                      <p class="text-muted font-small-3">Thank you </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-22.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Sarah Graves </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">11.14 PM</span>
                      </div>
                      <p class="text-muted font-small-3">Okay you </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-9.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Andrew Hoffman </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">7.30 PM</span>
                      </div>
                      <p class="text-muted font-small-3">Can do </p>
                    </div>
                  </div>
                  <div class="media mb-1">
                    <a>
                      <img alt="96x96" class="media-object d-flex mr-3 bg-primary height-50 rounded-circle"
                        src="assets/img/portrait/small/avatar-s-20.png">
                    </a>
                    <div class="media-body text-left">
                      <div class="clearfix">
                        <h4 class="font-medium-1 primary mt-1 mb-0 mr-auto float-left">Camila Lynch </h4>
                        <span class="medium-small float-right blue-grey-text text-lighten-3">2.00 PM</span>
                      </div>
                      <p class="text-muted font-small-3">Leave it </p>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle><b>Settings</b></ng-template>
            <ng-template ngbTabContent>
              <div id="settings" class="col-12">
                <h6 class="mt-1 mb-3 text-bold-400 text-left">GENERAL SETTINGS</h6>
                <ul class="list-unstyled">
                  <li class="text-left">
                    <div class="togglebutton">
                      <div class="switch d-flex justify-content-between w-100">
                        <span class="text-bold-500">Notifications</span>
                        <div class="notification-cb">
                          <div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                            <input checked="checked" class="custom-control-input cz-bg-image-display" type="checkbox"
                              id="notifications1">
                            <label class="custom-control-label d-block" for="notifications1"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>Use checkboxes when looking for yes or no answers.</p>
                  </li>
                  <li class="text-left">
                    <div class="togglebutton">
                      <div class="switch d-flex justify-content-between w-100">
                        <span class="text-bold-500">Show recent activity</span>
                        <div class="notification-cb">
                          <div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                            <input checked="checked" class="custom-control-input cz-bg-image-display" type="checkbox"
                              id="recent-activity1">
                            <label class="custom-control-label d-block" for="recent-activity1"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>The for attribute is necessary to bind our custom checkbox with the input.</p>
                  </li>
                  <li class="text-left">
                    <div class="togglebutton">
                      <div class="switch  d-flex justify-content-between w-100">
                        <span class="text-bold-500">Notifications</span>
                        <div class="notification-cb">
                          <div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                            <input class="custom-control-input cz-bg-image-display" type="checkbox" id="notifications2">
                            <label class="custom-control-label d-block" for="notifications2"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>Use checkboxes when looking for yes or no answers.</p>
                  </li>
                  <li class="text-left">
                    <div class="togglebutton">
                      <div class="switch d-flex justify-content-between w-100">
                        <span class="text-bold-500">Show recent activity</span>
                        <div class="notification-cb">
                          <div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                            <input class="custom-control-input cz-bg-image-display" type="checkbox"
                              id="recent-activity2">
                            <label class="custom-control-label d-block" for="recent-activity2"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>The for attribute is necessary to bind our custom checkbox with the input.</p>
                  </li>
                  <li class="text-left">
                    <div class="togglebutton">
                      <div class="switch d-flex justify-content-between w-100">
                        <span class="text-bold-500">Show your emails</span>
                        <div class="notification-cb">
                          <div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                            <input class="custom-control-input cz-bg-image-display" type="checkbox" id="show-emails">
                            <label class="custom-control-label d-block" for="show-emails"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>Use checkboxes when looking for yes or no answers.</p>
                  </li>
                  <li class="text-left">
                    <div class="togglebutton">
                      <div class="switch d-flex justify-content-between w-100">
                        <span class="text-bold-500">Show Task statistics</span>
                        <div class="notification-cb">
                          <div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
                            <input class="custom-control-input cz-bg-image-display" type="checkbox" id="show-stats">
                            <label class="custom-control-label d-block" for="show-stats"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>The for attribute is necessary to bind our custom checkbox with the input.</p>
                  </li>
                </ul>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</aside>
<!-- END Notification Sidebar -->