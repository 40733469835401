import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor, RefreshTokenService } from 'abp-ng2-module';

import * as App from './app-services';
import * as Content from './content-services';
import * as Profile from './profile-services';
import * as Course from './course-services';
import { AuthRefreshTokenService } from '../auth/auth-refresh-token.service';

@NgModule({
    providers: [
        App.RoleAppService,
        App.SessionAppService,
        App.TenantAppService,
        App.UserAppService,
        App.TokenAuthAppService,
        App.AccountAppService,
        App.ConfigurationAppService,

        Profile.ProfileAppService,
        Course.ActivityAppService,

        Content.QuizActivityAppService,
        Content.PresentationAppService,
        Course.ClassAppService,
        Course.AchievementsAppService,
        { provide: RefreshTokenService, useClass: AuthRefreshTokenService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class AppServicesModule { }
