export class AppConsts {

    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter answer if used during the publish
    static logoUrl: string;
    static localeMappings: any = [];
    static title: string = "Extracademy"

    static allowedOrigins: string[] = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Unify'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };


    static readonly signInRoute = '/sign-in'

    static readonly AppName = 'Workbook';
}
