import { Injectable } from '@angular/core';
import { MessageService } from 'abp-ng2-module';

import { ConfigurationAppService } from './core/app-services/app-services';
import { AppConsts } from './shared/app-consts';

@Injectable({
    providedIn: 'root',
  })
  export class AppConfig {

    version: string = '1.40';

    constructor(private _configService: ConfigurationAppService, private _messageService: MessageService) {

    }

    async getCurrentAppVersion(name: string): Promise<string> {
      var output = await this._configService.getConfig(name).toPromise();
      return output.currentVersion;

    }


    async checkVersion(): Promise<void> {
      console.log("Check version");
      var currentVersion = await this.getCurrentAppVersion(AppConsts.AppName);
        var installedVersion = this.version;

        if(currentVersion != installedVersion) {
            this.showUpgradeMessage(currentVersion);
        }
    }

    showUpgradeMessage(newVersion: string) {
      var message = `Version ${newVersion} is now available, please update your application by pressing the refresh button in your browser.`
      this._messageService.warn(message, 'Update application now!'); 
    }






  }